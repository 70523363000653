.myprofile__container {
  max-width: 545px;
}

.myprofile__input-group-1 label:first-child {
  margin-right: 0;
  flex-basis: 60px;
  flex-shrink: 0;
}

.myprofile__input-group-2 label:first-child {
  margin-right: 0;
  flex-basis: 95px;
  flex-shrink: 0;
}

.myprofile__input-group-3 label:first-child {
  margin-right: 0;
  flex-basis: 130px;
  flex-shrink: 0;
}

.myprofile__input-group-4 {
  display: flex;
  justify-content: flex-end;
}

.referral-content {
  &__attention {
    margin-bottom: 29px;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #4C37CA;
    @media (min-width: 768px) {
      margin-bottom: 46px;
    }
  }
}

.referral-form {
  margin-bottom: 10px;
  max-width: 624px;

  @media (min-width: 768px) {
    margin-bottom: 29px;
  }

  &__label {
    margin-bottom: 28px;
    font-weight: 800;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    @media (min-width: 768px) {
      margin-bottom: 14px;
    }
  }

  &__field-row {
    position: relative;
  }

  &__field {
    width: 100%;
    height: 64px;
    padding: 14px 70px 14px 14px;
    background: #FFFFFF;
    border: 0.397059px solid rgba(0, 0, 0, 0.2);
    box-shadow: 1.58824px 1.58824px 7.54412px rgba(0, 0, 0, 0.14);
    border-radius: 8px;

    &::placeholder {
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #A4A4A4;
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 64px;
    height: 64px;
    background-color: #4C37CA;
    border: 0;
    border-radius: 0 8px 8px 0;
    background-image: url('../../../../public/assets/img/search-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 40%;

    &._success {
      background-image: url('../../../../public/assets/img/check-angle.svg');
    }
  }
}


.referral-link {
  border-bottom: 1px dashed #A4A4A4;
  padding-bottom: 29px;
  margin-bottom: 21px;
  @media (min-width: 768px) {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #BCC9D6;
    padding-bottom: 42px;
  }

  &__label {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: #000000;

    &:before {
      content: '';
      display: flex;
      width: 28px;
      height: 28px;
      margin-right: 6px;
      background: url('../../../../public/assets/img/referral-link.svg') center center no-repeat;
    }

    @media (min-width: 768px) {
      white-space: nowrap;
      font-size: 16px;
      margin-right: 65px;
      margin-bottom: 0;
    }
  }

  &__field-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }

  &__field {
    width: 100%;
    height: 32px;
    padding: 5px 10px;
    background: #FFFFFF;
    border: 1px solid #DBE1E8;
    border-radius: 6px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #62626A;
    @media (min-width: 768px) {
      height: 27px;
    }
  }

  &__field-empty {
    margin-right: auto;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #62626A;
  }

  &__copy-btn {
    flex-shrink: 0;
    width: 19px;
    height: 19px;
    margin-left: 20px;
    cursor: pointer;
    background: url('../../../../public/assets/img/copy-icon.svg') center center no-repeat;
    background-size: contain;
    transition: opacity 0.3s;
    border: none;

    &:hover {
      opacity: 0.8;
    }
  }
}

.referral-link-message {
  margin-bottom: 36px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  border-bottom: 1px dashed #A4A4A4;

  @media (min-width: 768px) {
    color: #0F0F0F;
    border-bottom: 0;
    margin-bottom: 30px;
    padding-bottom: 0;
  }
}

.referral-stat-block {
  max-width: 852px;
  @media (min-width: 1024px) {
    padding: 43px 39px;
    background: #FFFFFF;
    border: 0.951955px solid #DBE1E8;
    border-radius: 9.51955px;
    zoom: 0.8;

  }

  @media (min-width: 1024px) {
    zoom: 0.9;
  }

  @media (min-width: 1200px) {
    zoom: 1;
  }

  &__header {
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 20px;
    line-height: 27px;
    color: #0F0F0F;

    span {
      color: #4C37CA;
      margin-left: 5px;
    }

    @media (min-width: 1024px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 44px;
    }
  }

  &__table-wrapper {
    display: none;
    @media (min-width: 1024px) {
      display: block;
    }
  }

  &__table {
    width: 100%;
    font-size: 14px;

    tr:last-child td {
      border-bottom: 0;
    }

  }

  &__table-head-cell, &__table-cell {
    text-align: left;
    height: 63px;
    padding: 0 0 0 12px;
    border-bottom: 0.951955px solid #DBE1E8;

    &._total {
      font-weight: 700;
      font-size: 20px;
      line-height: 27px;
      padding-right: 0;
    }

    &._bold {
      font-weight: 700;
    }

    &._num {
      padding-right: 0;
    }

    &._align-right {
      text-align: right;
    }

    &._nowrap {
      white-space: nowrap;
    }
  }

  &__table-head-cell {
    text-align: left;
    vertical-align: top;
  }


  &__mobile-message {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #0F0F0F;
    @media (min-width: 768px) {
      display: none;
    }
  }
}
