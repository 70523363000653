$main-search-form_main-font: 20px;
$main-search-form_main-font-mobile: 15px;


.search-field-wrapper {
  position: relative;
  width: 100%;
}

.search-field-wrapper.active {
  z-index: 120;
}

.search-field-popup {
  width: 100%;
  max-width: 826px;
  min-height: 100%;
  left: 20%;
  padding: 36px 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: white;

  @media (min-width: 768px) {
    padding: 36px 40px;
    min-height: 368px;
    border-radius: 13px;
  }
}

.search-field-popup.active {
  opacity: 1;
  pointer-events: auto;
}

.search-field-popup .close-btn {
  width: 28px;
  height: 28px;
  background-image: url("../../../../public/assets/img/search-close.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  margin-bottom: 75px;
}

.search-field {
  width: 100%;
  height: 77px;
  font-weight: 400;
  font-size: $main-search-form_main-font-mobile;
  line-height: 22px;
  color: #4d4d4d;
  background: #FFFFFF;
  border: 0.397059px solid rgba(0, 0, 0, 0.2);
  box-shadow: 1.58824px 1.58824px 7.54412px rgba(0, 0, 0, 0.14);
  border-radius: 10px;
  padding: 0 140px 0 20px;
  font-family: 'Manrope', sans-serif;
  @media (min-width: 768px) {
    font-size: $main-search-form_main-font
  }
}


.search-field::placeholder {
  color: #A4A4A4;
}

.search-field-popup .search-field {
  border: 0.75px solid #4C37CA;
  box-shadow: 3px 3px 14.25px rgba(0, 0, 0, 0.14);
  @media (min-width: 768px) {
    padding: 0 168px 0 20px;
  }
}

.search-btn {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 77px;
  height: 77px;
  border-radius: 0 10px 10px 0;
  border: 0;
  cursor: pointer;
  background: #4C37CA;
}

.search-btn:before {
  content: '';
  width: 41px;
  height: 41px;
  background: url('../../../../public/assets/img/search-icon.svg') center center no-repeat;
  background-size: contain;
}

.search-field-popup .search-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 123px;
  height: 77px;
  font-size: $main-search-form_main-font;
  padding: 25px 27px;
  font-weight: 700;

  @media (min-width: 786px) {
    width: 123px;
    height: 77px;
    font-size: $main-search-form_main-font;
  }
}

.search-field-popup .search-btn:before {
  display: none;
}

.search-btn img {
  width: 20px;
  height: 20px;
}

.search-result {
  margin-top: 28px;
  width: 100%;

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-top: 32px;
    margin-bottom: 30px;

    :empty {
      display: none;
    }

    .btn {
      padding: 16px 80px;
      color: white;
      background-color: #62626A;
      text-transform: none;
      font-size: 19px;
    }
  }


}

.valid-message {
  display: flex;
  align-items: center;
  background-color: #F7F0CE;
  width: 100%;
  border-radius: 10px;
  font-size: $main-search-form_main-font;
  padding: 24px 14px;
  color: #3F3F3F;
}

@media (min-width: 786px) {
  .valid-message {
    font-size: $main-search-form_main-font;
    padding: 21px 40px;
  }
}

.search-result-item {
  display: grid;
  grid-template-columns: 1fr min-content;
  align-items: center;
  width: 100%;
  height: 77px;
  border-radius: 13px;
  background: #fff;
  padding: 12px 140px 12px 20px;
  font-size: 15px;
  margin-bottom: 5px;
  position: relative;
  z-index: 4;
  border: 0.75px solid #4C37CA;
  box-shadow: 3px 3px 14.25px rgba(0, 0, 0, 0.14);

  @media (min-width: 786px) {
    font-size: $main-search-form_main-font;
    padding: 12px 169px 12px 2px;
    grid-template-columns: minmax(100px, 200px) minmax(min-content, 100px) 1fr;
  }

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 50%;

  }

  &__status {
    text-transform: lowercase;
    color: #a4a4a4;
    @media (min-width: 786px) {
      text-align: right;
    }

    &._available {
    }

    &._unavailable {
      color: #FF0000;
    }
  }

  &__price {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 786px) {
      justify-content: flex-end;
    }

    @media (max-width: 786px) {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 2;
      grid-row-end: 2;
    }

    &:empty {
      display: none;
    }

    &._no-discount {
      justify-content: flex-end;
    }

    &__primary {
      color: #0F0F0F;
      font-size: 19px;
      @media (min-width: 786px) {
        font-size: 24px;
      }
    }

    &__secondary {
      color: #A4A4A4;
      text-decoration: line-through;
    }

    @media (min-width: 786px) {
      :last-child {
        margin-left: 36px;
      }
    }
  }

  &__buy-btn {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 13px 13px 0;
    color: white;
    border: 1px solid #A4A4A4;
    cursor: pointer;
    position: absolute;
    top: -2px;
    right: -1px;
    transition-duration: .15s;
    white-space: nowrap;
    font-size: 17px;
    background-color: #A4A4A4;
    width: 123px;
    height: 78px;
    font-weight: 700;

    &.action-added {
      border-color: #69D714;
    }

    @media (min-width: 786px) {
      width: 123px;
      height: 78px;
      font-size: $main-search-form_main-font;
    }
  }
}

@media (min-width: 786px) {
  .search-result-item {
    font-size: $main-search-form_main-font;
    padding: 13px 169px 13px 20px;
    align-items: center;
    flex-wrap: nowrap;

    &__name {
      flex-grow: 1;
      min-width: auto;
    }

    &__price {
    }

    &__buy-btn {
    }
  }
}

.search-result-item__buy-btn.action-added {
  color: black;
  background: #69D714;
  transition-duration: .15s;
}

.search-frame {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(77, 77, 88, 30%);
  opacity: 0;
  transition-duration: .4s;
  transition-property: opacity;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-frame.active {
  opacity: 1;
  pointer-events: auto;
  z-index: 110;
}
