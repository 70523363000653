.default-input__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.default-input__container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.default-input {
    width: 100%;
    padding: 16px;
    border: 1px solid #4c37ca;
    border-radius: 13px;
}

.default-input::placeholder {
    font-family: 'Manrope', monospace;
    color: #afafaf;
}

.default-input__label {
    margin-right: 15px;
    text-transform: capitalize;
    font-weight: 700;
    color: #202020;
}

.default-input__sub-label {
    font-size: 12px;
    color: #afafaf;
    padding-left: 19px;
    margin-top: 5px;
}