.logoutButton {
    text-decoration: underline;
    color: #afafaf;
    border: none;
    background: none;
    font-size: inherit;
    cursor: pointer;
}

.logoutIcon {
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-bottom: -0.2em;
    background-image: url(/public/assets/img/logout.svg);
    background-size: contain;
    background-repeat: no-repeat;
}
