.sidebar__title {
    font-family: 'Fira Sans', sans-serif;
    text-transform: uppercase;
    font-size: 26px;
    font-weight: 700;
    color: #afafaf;
}

@media (max-width: 786px) {
    .sidebar__title {
        font-size: 40px;
    }
}

.sidebar__list {
    padding-top: 40px;
}

.sidebar__list > * {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    font-size: 16px;
    color: #62626a;
    text-decoration: none;
    transition-duration: .15s;
    cursor: pointer;
    font-weight: 500;
}

.sidebar__list > *.active {
    color: #4c37ca;
}

.sidebar__list > *:hover {
    opacity: 0.7;
}

.sidebar__footer {
    border-top: 1px solid rgb(188 201 214 / 80%);
    margin-top: 20px;
    padding-top: 20px;
    color: #afafaf;
}

