.developers-header {
    padding: 61px 0 40px;
}

.developers-header__title {
    margin-bottom: 40px;
    font-size: 25px;
    line-height: 34px;
    font-weight: 500;
    text-align: center;
    color: #202020;
    font-family: 'Fira Sans', sans-serif;
}

.developers-header__text {
    margin: 0 auto;
    max-width: 346px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
    text-align: center;
    letter-spacing: .01em;
    color: #3e3d3d;
}

.developers-form-wrapper {
    margin-bottom: 80px;
    width: 100%;
    background: #ffffff;
}

.developers-form {
    padding: 40px 0;
    width: 100%;
    background: #ffffff;
}

.developers-form__field-wrap {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
}

.developers-form__field-label {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #4d4d4d;
}

.developers-form__field {
    padding: 16px;
    background: #ffffff;
    border: 2px solid #8f8f8f;
    border-radius: 13px;
}
.developers-form__field_error {
    border: 2px solid red;
}
.developers-form__field-error {
    color: red;
    margin-top: 8px;
    font-size: 14px;
    min-height: 19px;
}

.developers-form__field._textarea {
    height: 200px;
    font-family: 'Roboto Mono', monospace;
}

.attach-file-block {
    margin-bottom: 47px;
    display: flex;
}

.developers-form__file-button {
    margin-right: 40px;
    display: flex;
    width: 231px;
    height: 59px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    color: #ffffff;
    background: #4c37ca;
    border: 1px solid #4c37ca;
    border-radius: 10px;
    cursor: pointer;
}

.developers-form__file-field {
    display: none;
}

.developers-form__file-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.developers-form__file {
    border-radius: 10px;
    padding: 14px 16px;
    transition: 0.5s;
    border: 1px solid #4c37ca;
}

.developers-form__submit-button {
    display: flex;
    cursor: pointer;
    width: 100%;
    height: 43px;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
    color: #ffffff;
    background: #4c37ca;
    border: 1px solid #4c37ca;
    border-radius: 10px;
}

.partners {
    background: #ffffff;
    padding: 40px 0;
}

.partners__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -20px 60px -20px;
}

.partners__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.partners__item-logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.opera-img {
    width: 95px;
    height: 35px;
    background-image: url('../../../public/assets/img/partners/opera.svg');
}

.bitcoin-img {
    width: 118px;
    height: 17px;
    background-image: url('../../../public/assets/img/partners/bitcoin.svg');
}

.huobi-img {
    width: 81px;
    height: 25px;
    background-image: url('../../../public/assets/img/partners/huobi.svg');
}

.blockchain-img {
    width: 151px;
    height: 18px;
    background-image: url('../../../public/assets/img/partners/blockchain.svg');
}

.mercuryo-img {
    width: 128px;
    height: 18px;
    background-image: url('../../../public/assets/img/partners/mercuryo.svg');
}

.mew-img {
    width: 85px;
    height: 23px;
    background-image: url('../../../public/assets/img/partners/mew.svg');
}

.linch-img {
    width: 107px;
    height: 54px;
    background-image: url('../../../public/assets/img/partners/linch.svg');
}

.brave-img {
    width: 92px;
    height: 35px;
    background-image: url('../../../public/assets/img/partners/brave.svg');
}

.trust-img {
    width: 104px;
    height: 21px;
    background-image: url('../../../public/assets/img/partners/trust.svg');
}

.polygon-img {
    width: 103px;
    height: 22px;
    background-image: url('../../../public/assets/img/partners/polygon.svg');
}

.partners__view-all {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 221px;
    height: 34px;
    border: 1px solid #4c37ca;
    border-radius: 100px;
    margin: 0 auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #4c37ca;
    background: #ffffff;
}


@media (min-width: 768px) {
    .developers-header__title {
        font-size: 53px;
        line-height: 70px;
    }

    .developers-header__text {
        max-width: 100%;
        font-size: 20px;
        line-height: 26px;
    }

    .developers-header {
        padding: 138px 0 110px 0;
    }

    .developers-form-wrapper {
        background: transparent;
    }

    .developers-form {
        padding: 74px 120px;
    }

    .developers-form__row._col {
        display: flex;
        margin: 0 -20px;
    }

    .developers-form__cell {
        width: 50%;
        flex-shrink: 0;
        padding: 0 20px;
    }

    .developers-form__field-label {
        font-size: 21px;
    }

    .developers-form__field {
        height: 54px;
        font-size: 21px;
    }

    .developers-form__file-button,
    .developers-form__file-text,
    .developers-form__submit-button {
        font-size: 21px;
    }

    .developers-form__submit-button {
        height: 53px;
    }

    .partners__item {
        width: 20%;
    }

    .partners {
        padding: 122px 0;
    }

    .attach-file-block {
        margin-top: 54px;
    }
}