.congrat-wrapper {
    padding-top: 80px;
    padding-bottom: 80px;
    min-height: 583px;
    flex: 1;
    background: url("../../../public/assets/img/congrat-bg-mobile.svg") center center no-repeat;
    background-size: cover;
}

.congrat-title {
  margin-bottom: 50px;
  text-align: center;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  text-transform: uppercase;
  color: #4c37ca;
}

.congrat-title span {
    color: #04c8a0;
}

.congrat-button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 221px;
  height: 34px;
  border: 1px solid #4c37ca;
  border-radius: 100px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #4c37ca;
  cursor: pointer;
  text-decoration: none;
}

.congrat-button:hover {
  background: #4c37ca;
  color: #ffffff;
}

@media (min-width: 768px) {
    .congrat-wrapper {
        padding-top: 160px;
        background: url("../../../public/assets/img/congrat-bg-desktop.svg") center center no-repeat;
    }

    .congrat-title {
        font-weight: 500;
        font-size: 26px;
        line-height: 34px;
        margin-bottom: 55px;
    }
}
