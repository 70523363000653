.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #f2faff;
  z-index: 10;
  overflow: auto;
  &__inner {
    max-width: 1440px;
    width: 100%;
    min-height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 53px;
    .logo img {
      width: 130px;
      display: block;
    }
  }
  &__close {
    padding: 5px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
  &__content {
    margin: 0 auto;
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

