.fileinput {
  &__field {
    display: block;
    width: 100%;
    padding: 8px 20px;
    font-weight: 400;
    appearance: none;
    transition-duration: .15s;
    overflow: hidden;

    &::file-selector-button {
      padding: 8px 20px;
      margin: (-8px) (-20px);
      margin-inline-end: 20px;
      color: #ffffff;
      pointer-events: none;
      background-color: #4c37ca;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-inline-end-width: 1px;
      border-radius: 10rem;
    }

    &:not(:disabled):not([readonly]) {
      cursor: pointer;
    }

    &:hover:not(:disabled):not([readonly])::file-selector-button {
      opacity: 0.8;
    }
  }

  &__error {
    display: block;
    margin-top: 8px;
    color: red;
  }

}


