.claim {
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-top: 60px;
        padding-bottom: 153px;
        flex: 1;
    }
    &__inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1080px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;
        text-align: center;
    }
}
.loader-circle {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader-circle-inner {
    width: 96px;
    height: 96px;
    animation: 1s linear infinite rotate;;
    background: url("../../../public/assets/img/loader-circle.svg") center center no-repeat;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}




.welcome-title {
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  color: #4c37ca;
}

.welcome-text {
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #3e3d3d;
}

.welcome-field {
    width: 100%;
    height: 50px;
    margin-bottom: 24px;
    padding: 0 16px;
    background: #ffffff;
    border: 2px solid #8f8f8f;
    border-radius: 13px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
}

.welcome-field::placeholder {
    color: #afafaf;
}

.welcome-button {
  width: 100%;
  height: 43px;
  background: #4c37ca;
  border: 1px solid #4c37ca;
  border-radius: 10px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

    color: #ffffff;
}

.welcome-button:hover {
    opacity: 0.6;
}

@media (min-width: 768px) {
    .welcome-title {
        margin-bottom: 20px;
        font-weight: 500;
        font-size: 53px;
        line-height: 70px;
    }

    .welcome-text {
        font-weight: 500;
        font-size: 20px;
        line-height: 26px;
    }

    .welcome-field {
        height: 54px;
        font-weight: 400;
        font-size: 21px;
    }

    .welcome-button {
        font-weight: 500;
        font-size: 21px;
        line-height: 28px;
        height: 54px;
    }
}
