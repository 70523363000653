.dna-block {
    padding: 100px 0;
    background: #f5f5f5;
}

@media (min-width: 786px) {
    .dna-block .inner {
        display: flex;
    }
}

.dna-block__content {
    max-width: 630px;
    width: 100%;
    margin: 0 auto 40px auto;
    display: flex;
    flex-direction: column;
}

.dna-block__title {
    font-family: 'Fira Sans', sans-serif;
    width: 100%;
    margin-bottom: 38px;
    font-weight: 700;
    font-size: 33px;
    line-height: 40px;
    letter-spacing: -0.01em;
    color: #202020;
    text-align: center;
}

@media (min-width: 786px) {
    .dna-block__title br {
        display: none;
    }

    .dna-block__title {
        font-size: 47px;
        line-height: 58px;
        margin-bottom: 30px;
        text-align: left;
    }
}

.dna-block__title span {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 43px;
    line-height: 52px;
}

@media (min-width: 786px) {
    .dna-block__title span {
        display: inline;
        text-align: left;
        font-size: 47px;
        line-height: 58px;
    }
}

.dna-block__intro {
    margin-bottom: 9px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #62626a;
}

@media (min-width: 786px) {
    .dna-block__intro {
        text-align: left;
        font-size: 25px;
        margin-bottom: 12px;
    }
}

.dna-block__any-language {
    margin-bottom: 42px;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    text-align: center;
    color: #4c37ca;
}

@media (min-width: 786px) {
    .dna-block__any-language {
        text-align: left;
        font-size: 25px;
    }
}

.dna-block__pay {
    margin-bottom: 49px;
    font-size: 15px;
    line-height: 21px;
    color: #0f0f0f;
    text-align: center;
}


.dna-block__pay span {
    font-size: 17px;
    line-height: 23px;
    font-weight: 700;
}

@media (min-width: 786px) {
    .dna-block__pay {
        text-align: left;
        order: 1;
        padding-top: 61px;
        font-size: 25px;
    }

    .dna-block__pay span {
        font-size: 25px;
    }
}

.dna-block__pictures {
    margin: 0 -20px;
    height: 631px;
    background: url('../../../public/assets/img/top-banner.png') center center no-repeat;
    background-size: cover;
}

@media (min-width: 786px) {
    .dna-block__pictures {
        width: 573px;
        height: 531px;
        background: url('../../../public/assets/img/first_block_desctop.png') center center no-repeat;
        background-size: contain;
        margin-left: 30px;
        margin-top: -50px;
    }
}