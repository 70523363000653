.textinput {
  width: 100%;
  margin: 15px 0;

  &__container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__field {
    width: 100%;
    padding: 16px;
    border: 1px solid #4c37ca;
    border-radius: 13px;

    &::placeholder {
      font-family: 'Roboto Mono', monospace;
      color: #afafaf;
    }

    &_error {
      border: 1px solid red;
    }
  }

  &__label {
    margin-right: 15px;
    text-transform: uppercase;
  }

  &__sub-label {
    font-size: 12px;
    color: #afafaf;
    padding-left: 19px;
    margin-top: 5px;
  }

  &__error {
    color: red;
    margin-top: 8px;
    font-size: 14px;
    min-height: 19px;
  }
}


