.sidebar-layout {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    padding-bottom: 40px;
    padding-top: 80px;
}

@media (max-width: 786px) {
    .sidebar-layout {
        flex-direction: column;
        padding-top: 20px;
    }
}

.sidebar-layout__sidebar {
    flex-basis: 200px;
    flex-shrink: 0;

    padding-right: 22px;
    border-right: 1px solid #bcc9d6;
}

@media (max-width: 786px) {
    .sidebar-layout__sidebar {
        order: 1;
        border-right: none;
        border-top: 1px solid #bcc9d6;
        padding-top: 40px;
        padding-right: 0;
    }
}

.sidebar-layout__content {
    display: flex;
    flex-direction: column;

    flex-grow: 1;
    flex-shrink: 0;

    padding-left: 40px;
    max-width: calc(100% - 40px - 160px);
}

@media (max-width: 786px) {
    .sidebar-layout__content {
        padding-left: 0;
        padding-bottom: 40px;
        max-width: unset;
    }
}