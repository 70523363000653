.default-icon-button {
    padding: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 37px;
    height: 37px;
}

.default-icon-button._icon-trashcan {
    background-image: url(/public/assets/img/trashcan.svg);
    background-size: 30%;
}