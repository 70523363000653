@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@400;500;700&family=Roboto+Mono:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600;700;800&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: 0;
}

html {
    overflow-x: hidden;
}

body {
    padding: 0;
    margin: 0;
    font-family: 'Manrope', sans-serif;
    background: #fafafa;
    min-width: 414px;
    color: #4d4d4d;
}

html, body, #root, .wrapper {
    min-height: 100vh;
}

a {
    color: #4c37ca;
}

.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.inner {
    max-width: 1300px;
    width: 100%;
    padding: 0 20px;
    margin: 0 auto;
    flex-grow: 1;
}


.d-none {
    display: none !important;
}

@media (max-width: 786px) {
    .d-ms-none {
        display: none !important;
    }
}

h1, h2 {
    font-family: 'Fira Sans', sans-serif;
    text-transform: capitalize;
    margin-top: 40px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #4c37ca;
}

h1:first-child {
    margin-top: 0;
    margin-bottom: 40px;
    color: #202020;
}

h1 {
    font-size: 26px;
}

h3, h4 {
    font-family: 'Fira Sans', sans-serif;
    text-transform: uppercase;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 10px;
}

h3:first-child, h4:first-child {
    margin-top: 0;
    margin-bottom: 20px;
}

h3 {
    color: #4c37ca;
}

h4 {
    color: #afafaf;
}
