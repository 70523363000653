

.domain-block {
  padding-top: 90px;
}

@media (min-width: 768px) {
  .domain-block {
    padding-top: 120px;
  }
}

.domain-block__title {
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 22px;
  font-size: 26px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .domain-block__title {
    margin-bottom: 24px;
    font-size: 55px;
    line-height: 60px;
  }
}

.domain-block__items {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
  font-size: 14.247px;
  line-height: 19px;
  font-weight: 400;
  text-align: center;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .domain-block__items {
    font-size: 21px;
    line-height: 29px;
  }
}

.domain-block__item {
  display: flex;
  align-items: center;
  padding: 0 21px;
  font-weight: 700;
}


.domain-block__item:before {
  content: '';
  display: block;
  margin-right: 12px;
  width: 13px;
  height: 13px;
  background-size: cover;
}

@media (min-width: 786px) {
  .domain-block__item:before {
    width: 24px;
    height: 24px;
  }
}

.domain-block__item._domain::before {
  background: url('../../../../public/assets/img/domain-icon.svg') center center no-repeat;
  background-size: cover;
}

.domain-block__item._name::before {
  background: url('../../../../public/assets/img/name-icon.svg') center center no-repeat;
  background-size: cover;
}

.domain-block__item._account::before {
  background: url('../../../../public/assets/img/account-icon.svg') center center no-repeat;
  background-size: cover;
}

.text-block {
  display: flex;
  flex-direction: column;
  padding: 50px 0;
  text-align: center;
}

.text-block._name {
  padding-top: 0;
}

.text-block-wrapper {
  background: #f5f5f5;
}

@media (min-width: 786px) {
  .text-block {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: left;
    max-width: 1300px;
    width: 100%;
    padding: 90px 20px;
    margin: 0 auto;
  }

  .text-block._name .text-block__preview {
    order: 1;
  }
}

.text-block__preview {
  background: #f5f5f5;
  background-size: contain;
}

.text-block__preview._mac {
  width: 100%;
  height: 280px;
  background: url('../../../../public/assets/img/mac.png') center center no-repeat;
  background-size: 85%;
}

@media (min-width: 786px) {
  .text-block__preview._mac {
    width: 760px;
    height: 379px;
  }
}


.text-block__preview._name-block {
  width: 100%;
  height: 322px;
  background: url('../../../../public/assets/img/name_mobile_bg.png') center center no-repeat;
  background-size: contain;
}

@media (min-width: 786px) {
  .text-block__preview._name-block {
    width: 585px;
    height: 621px;
    background: url('../../../../public/assets/img/Name_mobile_screens.png') center center no-repeat;
  }
}

.text-block__preview._account {
  width: 100%;
  height: 662px;
  background: url('../../../../public/assets/img/account_mobile_bg.png') center center no-repeat;
  background-size: cover;
}

@media (min-width: 786px) {
  .text-block__preview._account {
    width: 565px;
    height: 637px;
    background: url('../../../../public/assets/img/mobile_account_screens.png') center center no-repeat;
    background-size: cover;
  }
}

.text-block__info {

}

@media (min-width: 786px) {
  .text-block._domain .text-block__info {
    max-width: 405px;
  }

  .text-block._name .text-block__info {
    max-width: 342px;
  }

  .text-block._account .text-block__info {
    max-width: 374px;
  }
}

@media (min-width: 1100px) {
  .text-block._domain .text-block__info {
    margin-left: 70px;
  }

  .text-block._name .text-block__info {
    margin-right: 176px;
  }

  .text-block._account .text-block__info {
    margin-left: 136px;
  }
}


.text-block__title {
  font-family: 'Fira Sans', sans-serif;
  padding: 50px 0 30px 0;
  font-weight: 500;
  font-size: 43px;
  line-height: 43px;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .text-block__title {
    padding: 0;
    margin-bottom: 20px;
    font-size: 63px;
    line-height: 50px;
  }
}

.text-block__text {
  margin-bottom: 40px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  letter-spacing: 0.04em;
  padding: 0 30px;
}

@media (min-width: 786px) {
  .text-block__text {
    padding: 0;
    font-size: 21px;
    line-height: 29px;
  }
}

.text-block__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 178px;
  height: 37px;
  background: #4c37ca;
  border: 0.64376px solid #4c37ca;
  border-radius: 8.67188px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  text-decoration: none;
  transition: 0.3s;
}

.text-block__button:hover {
  opacity: 0.8;

}

@media (min-width: 786px) {
  .text-block__button {
    margin: 0;
    height: 48px;
    width: 212px;
    font-size: 21px;
  }
}


.be-the-one {
  text-align: center;
  padding: 50px 32px;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #f0ffe1;
}

@media (min-width: 786px) {
  .be-the-one {
    padding: 120px 32px;
  }
}

.be-the-one__title {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 33px;
  line-height: 100%;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .be-the-one__title {
    font-family: 'Fira Sans', sans-serif;
    margin-bottom: 60px;
    font-weight: 800;
    font-size: 60px;
    line-height: 59px;
  }
}

.be-the-one__subtitle {
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 37px;
  font-weight: 700;
  font-size: 24px;
  line-height: 24px;
  color: #62626a;
}

@media (min-width: 786px) {
  .be-the-one__subtitle {
    margin-bottom: 60px;
    font-weight: 700;
    font-size: 39px;
  }
}

.be-the-one__text {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #0f0f0f;
}

@media (min-width: 390px) {
  .be-the-one__text {
    font-size: 18px;
  }
}

@media (min-width: 786px) {
  .be-the-one__text {
    font-weight: 500;
    font-size: 26px;
    line-height: 32px;
    max-width: 660px;
    margin: 0 auto;

  }
}

.capabilities {
  padding: 80px 0;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #f0ffe1;
}

.capabilities__items {
  display: flex;
  flex-direction: column;
}

@media (min-width: 786px) {
  .capabilities__items {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.capabilities__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .capabilities__item {
    margin: 0;
  }
}

.capabilities__item-icon {
  margin-bottom: 10px;
}

.capabilities__item-icon._com {
  width: 73px;
  height: 41px;
  background: url('../../../../public/assets/img/com.svg') center center no-repeat;
}

.capabilities__item-icon._dollar {
  font-weight: 600;
  font-size: 39.3629px;
  line-height: 40px;
  color: #62626a;
}

.capabilities__item-icon._any-symbol {
  width: 35px;
  height: 35px;
  background: url('../../../../public/assets/img/any_symbol.svg') center center no-repeat;
}

.capabilities__item-icon._any-language {
  width: 39px;
  height: 39px;
  background: url('../../../../public/assets/img/any_language.svg') center center no-repeat;
}

.capabilities__item-name {
  font-weight: 600;
  font-size: 29px;
  line-height: 30px;
  text-align: center;
  color: #0f0f0f;
}

.chain .text {
  font-size: 18px;
  line-height: 23px;
  font-weight: 500;
  text-align: center;
  color: #4d4d4d;
}
.view-supported-wrapper {
  background: #ffffff;
}

.view-supported {
  position: relative;
  padding: 44px 0;
}

@media (min-width: 768px) {
  .view-supported {
    padding: 120px 0 80px 0;
  }
}

.view-supported__title {
  font-family: 'Fira Sans', sans-serif;
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  text-align: center;
  color: #0f0f0f;
}

@media (min-width: 786px) {

  .view-supported__title {
    margin: 0 auto 20px;
    max-width: 720px;
    font-size: 53px;
    line-height: 70px;
  }

  .view-supported__title br {
    display: none;
  }
}


.view-supported__subtitle {
  margin-bottom: 66px;
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.04em;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .view-supported__subtitle {
    margin-bottom: 79px;
    font-size: 26px;
    line-height: 32px;
    text-align: center;
  }
}


.supported-items {
  display: flex;
  flex-wrap: wrap;
}

.supported-item {
  padding: 23px 10px;
  width: 33.3%;
}

.supported-item__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.supported-item__label {
  margin-bottom: 14px;
  padding: 0 8px;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: -0.03em;
  color: #ffffff;
  background: #4c37ca;
  border-radius: 703.353px;
}

@media (min-width: 786px) {
  .supported-item__label {
    width: 137px;
    height: 27px;
    font-size: 16px;
    margin-bottom: 32px;
  }
}

.supported-item__icon {
  margin-bottom: 14px;
  overflow: hidden;
  width: 55px;
  height: 55px;
}

@media (min-width: 786px) {
  .supported-item__icon {
    margin-bottom: 24px;
    width: 90px;
    height: 90px;
  }
}

.supported-item__name {
  margin-bottom: 12px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.03em;
  color: #0f0f0f;
}

.supported-item__img {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}
.b1 {
  background-image: url('/public/assets/img/b1.png');
  background-size: 100%;
}
.b2 {
  background-image: url('/public/assets/img/b2.png');
}
.b3 {
  background-image: url('/public/assets/img/b3.png');
}
.b4 {
  background-image: url('/public/assets/img/b4.png');
}
.b5 {
  background-image: url('/public/assets/img/b5.png');
}
.b6 {
  background-image: url('/public/assets/img/b6.png');
}

.faq {
  padding: 100px 0;
  max-width: 970px;
  margin: 0 auto;
}

.faq__title {
  margin-bottom: 75px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 33px;
  line-height: 40px;
  text-align: center;
  text-transform: capitalize;
  color: #0f0f0f;
}

@media (min-width: 786px) {
  .faq__title {
    font-size: 73px;
    line-height: 88px;
    margin-bottom: 82px;
  }
}

.faq-tabs-nav {
  margin-bottom: 60px;
  text-align: center;
}

.faq-tabs-nav__item {
  position: relative;
  padding-bottom: 6px;
  display: inline-block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;
  font-style: normal;
  text-align: center;
  color: #7cbbee;
}

.faq-tabs-nav__item._is-active {
    color: #4c37ca;
}

.faq-tabs-nav__item._is-active::before {
    background: #4c37ca;
}

.faq-tabs-nav__item:first-child {
  margin-right: 75px;
}

.faq-tabs-nav__item::before {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  content: '';
  background: #7cbbee;
}

.dropdown {
  overflow: hidden;
}

.dropdown .title {
  position: relative;
  padding: 20px 0 20px 50px;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #0f0f0f;
  border-bottom: 1px solid #d9d9d9;
  cursor: pointer;
}

@media (min-width: 786px) {
  .dropdown .title {
    font-size: 22px;
    line-height: 30px;
  }
}

.dropdown .title::before {
  position: absolute;
  top: 50%;
  left: 0;
  width: 22px;
  height: 22px;
  content: '';
  background: url('/public/assets/img/plus.svg') center center no-repeat;
  background-size: contain;
  transform: translateY(-50%);
}

.dropdown .title.expanded {
  border-bottom: 0;
}

.dropdown .title.expanded::before {
  width: 22px;
  height: 2px;
  background: #4c37ca;
}

.dropdown .text {
  padding: 20px 0 20px 50px;
  display: none;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #0f0f0f;
  border-bottom: 1px solid #d9d9d9;
}

@media (min-width: 786px) {
  .dropdown .text {
    font-size: 22px;
    line-height: 30px;
  }
}
.dropdown .text.expanded {
  display: block;
}

.faq-tabs-content {
  display: none;
}

.faq-tabs-content._is-active {
  display: block;
}

.faq-tabs-content__footer {
  padding-top: 52px;
}

.faq-view-all {
    margin: 0 auto;
    display: flex;
    width: 159px;
    height: 31px;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    background: #fafafa;
    border: 1px solid #4c37ca;
    border-radius: 100px;
}

.search-block__title {
  margin-bottom: 20px;
  font-family: 'Fira Sans', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 27px;
  line-height: 32px;
  text-align: center;
  color: #000000;
}

@media (min-width: 786px) {
  .search-block__title {
    margin-bottom: 50px;
    font-size: 60px;
    line-height: 72px;
  }
}


.search-block__field-wrapper {
  max-width: 827px;
  margin: 0 auto;
}

.search-block {
  padding: 50px 0 50px;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5), #f0ffe1;
}

.search-block .inner .search-field-wrapper {
  margin-bottom: 0;
}

.search-container {
    position: relative;
    padding: 3px;
    width: 100%;
    height: 68px;
    background: linear-gradient(89.67deg, #4c37ca -8.21%, #03e3b8 98.03%);
    border-radius: 12px;
    box-shadow: 0 1px 19px rgb(3 227 184 / 29%);
}
.search-container__field {
  padding: 0 50px 0 17px;
  width: 100%;
  height: 62px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #4d4d4d;
  background: #ffffff;
  border: 0;
  border-radius: 9px;
}
.search-container__button {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    background: linear-gradient(89.67deg, #4c37ca -8.21%, #03e3b8 98.03%);
    border: 0;
    border-radius: 8px;
    cursor: pointer;
}

.search-container__button::before {
  width: 26px;
  height: 26px;
  content: '';
  background: url('/public/assets/img/search-button.svg');
}

@media (min-width: 786px) {
  .integration-items {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .integration-item-wrapper {
    width: 50%;
  }

  .counters__cell {
    width: 25%;
  }
}

@media (min-width: 1024px) {
  .supported-items {
    justify-content: center;
  }

  .supported-item__name {
    min-height: 46px;
    font-size: 18px;
    line-height: 23px;
  }

  .search-block {
    padding: 154px 0;
  }

  .search-block__logo {
    margin: 0 48px 0 0;
    width: 249px;
    height: 59px;
  }

  .search-container {
    height: 57px;
  }

  .search-container__field {
    height: 51px;
  }

  .search-container__button {
    top: 8px;
    right: 8px;
    width: 41px;
    height: 41px;
  }

  .ambassadors-carousel__top {
    padding-top: 82px;
    height: 312px;
  }

  .ambassadors-carousel__title {
    font-size: 53px;
    line-height: 70px;
  }

  .ambassadors-carousel__track {
    margin: -85px auto 0;
    padding: 0 20px;
    width: 100%;
    max-width: 1080px;
  }

  .ambassadors-carousel__item-wrap {
    padding: 0 43px;
  }

  .ambassadors-carousel__nav {
    padding-top: 60px;
    display: flex;
    justify-content: center;
  }

  .ambassadors-carousel__nav-button {
      margin: 0 10px;
      display: flex;
      width: 50px;
      height: 50px;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 2px solid #4c37ca;
      border-radius: 50%;
      cursor: pointer;
  }

  .ambassadors-carousel__nav-button._prev::before {
    display: block;
    width: 12px;
    height: 16px;
    content: '';
    background: url('/public/assets/img/blue-arrow-left.svg') center center no-repeat;
  }

  .ambassadors-carousel__nav-button._next::before {
    display: block;
    width: 12px;
    height: 16px;
    content: '';
    background: url('/public/assets/img/blue-arrow-right.svg') center center no-repeat;
  }
}
