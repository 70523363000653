.mycoupons {
    &__table {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    &__table .tr {
        display: contents;
    }
    &__table .th {
      display: flex;
      flex-direction: row;
      align-items: center;
      text-align: left;
      border-bottom: 1px solid #4c37ca;
      color: #4c37ca;
      padding: 0 15px 20px;
      text-transform: capitalize;
    }
    &__table .td {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-align: left;
        padding: 20px 15px;
        border-bottom: 1px solid #1c96e8;
    }
    &__coupon-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        overflow: hidden;
    }
    &__loader {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
    }
    &__empty_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        flex-grow: 1;
    }
}

.mycoupons__table .td:nth-child(3),
.mycoupons__table .td:nth-child(4) {
    padding-top: 7px;
    padding-bottom: 7px;
}

@media (max-width: 768px) {
    .mycoupons__table .td:nth-child(3),
    .mycoupons__table .td:nth-child(4) {
        padding-left: 3px;
        padding-right: 3px;
    }
}

@media (max-width: 768px) {
    .mycoupons__table .button {
        padding-left: 10px;
        padding-right: 10px;
    }
}

