.cart-modal {
  display: flex;
}

.cart-modal__header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 53px;
}

.cart-modal__close-btn {
    padding: 5px;
    cursor: pointer;
}

.cart-modal__close-btn img {
    width: 24px;
    height: 24px;
    display: block;
}

.cart-modal__loader {
    display: flex;
    min-height: 100%;
    justify-content: center;
}

.cart-modal__content {
    max-width: 548px;
    width: 100%;
    padding: 75px 0 120px 0;
    margin: 0 auto;
}

.cart-modal__content-block {
    position: relative;
    padding-bottom: 26px;
    border-bottom: 1px dashed #A4A4A4;
    margin-bottom: 26px;
    @media (min-width: 768px) {
        padding-bottom: 32px;
        margin-bottom: 32px;
    }
}

.added-items-label {
    display: flex;
    align-items: center;
    font-size: 24px;
    color: #202020;
    margin-bottom: 24px;
    font-family: 'Fira Sans', sans-serif;
    font-weight: 700;
    @media (min-width: 786px) {
        font-weight: 700;
        font-size: 26px;
        margin-bottom: 24px;
    }
}


.added-items-label__counter {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    margin-right: 10px;
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    background: #4C37CA;
}


.added-items .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 43px;
    padding: 0 13px;
    border: 1px solid #4C37CA;
    border-radius: 8px;
    font-size: 14px;
    color: #4d4d4d;
    margin-bottom: 3px;
    @media (min-width: 768px) {
        height: 50px;
        margin-bottom: 15px;
    }
}

.added-items .item:last-child {
    margin-bottom: 0;
}

.added-items .item__price {
    margin-left: auto;
    font-weight: 500;
    font-size: 18px;
    color: #0F0F0F;
}

.added-items .item__price._discount span {
    font-weight: 500;
    font-size: 16px;
    color: #A4A4A4;
    margin-right: 18px;
}

.added-items .item__name {
    font-size: 16px;
    font-weight: 500;
    color: #0F0F0F;
}

.added-items .item__clear {
    display: flex;
    margin-left: 18px;
    cursor: pointer;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.5;
    }
}

.added-items .item__clear img {
    width: 14px;
    height: 16px;
}

@media (min-width: 786px) {
    .added-items .item__clear img {
        width: 16px;
        height: 16px;
    }
}

.coupon-code {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    &__field-wrap {
        flex: 1;
        position: relative;
    }
    &__field {
        width: 100%;
        height: 45px;
        border: 1px solid #4C37CA;
        border-radius: 8px;
        padding: 0 12px;
        font-size: 16px;

        &::placeholder {
            font-weight: 500;
            font-size: 16px;
            color: #A4A4A4;
        }

        @media (min-width: 768px) {
            height: 50px;
        }
    }
    &__field-label {
        position: absolute;
        top: 14px;
        right: 20px;
        color: #4C37CA;
        font-size: 12px;
        opacity: 0;
        transition-duration: .15s;

        &.active {
            opacity: 1;
        }
    }
    &__apply {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 134px;
        height: 45px;
        margin-left: 16px;
        background: #4C37CA;
        font-weight: 800;
        font-size: 14px;
        color: #fff;
        border-radius: 10px;
        border: 0;
        cursor: pointer;
        transition: opacity 0.3s;

        &:hover {
            opacity: 0.8;
        }

        @media (min-width: 768px) {
            font-size: 16px;
            height: 50px;
        }
    }
    &__feedback {
        width: 100%;
        font-size: 14px;
        min-height: 19px;
        position: absolute;
        left: 0;
        bottom: 3px;
    }
}

@media (min-width: 786px) {
    .coupon-code__field-label {
        top: 15px;
        font-size: 14px;
    }
}

.total-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
}

.total-price__label {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #202020;

    @media (min-width: 786px) {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }
}


.total-price-main {
    margin-left: 34px;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #000000;

    @media (min-width: 786px) {
        font-weight: 700;
        font-size: 24px;
        line-height: 33px;
    }
}


.total-price .full-price {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #A4A4A4;
    text-decoration: line-through;

}


.select-pay-label {
    font-weight: 500;
    font-size: 14px;
    color: #202020;
    margin-bottom: 15px;
}

.select-pay-buttons {
    display: flex;
    flex-direction: column;
}

.form_radio {
    display: inline-block;
    margin-bottom: 14px;

    &:last-child {
        margin-bottom: 0;
    }
}

.form_radio input[type=radio] {
    display: none;
}

.form_radio label {
    display: inline-flex;
    cursor: pointer;
    position: relative;
    padding-left: 35px;
    margin-right: 0;
    user-select: none;
    font-weight: 500;
    font-size: 16px;
    color: #62626A;
    @media (min-width: 768px) {
        padding-left: 25px;
    }
}

.form_radio label img {
    min-height: 21px;
    margin-left: 7px;
    display: inline-block;
    vertical-align: middle;
}


.form_radio label:after {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    border-radius: 50%;
    border: 1px solid #4C37CA;
    width: 24px;
    height: 24px;
    @media (min-width: 768px) {
        width: 15px;
        height: 15px;
        top: 2px;
    }
}

.form_radio label:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 6px;
    top: 4px;
    background-size: cover;
    @media (min-width: 768px) {
        width: 9px;
        height: 9px;
        top: 6px;
        left: 4px;
    }
}

.form_radio input[type=radio]:checked + label:before {
    background: #4C37CA;
}

.confirm-block {

}


.confirm-button {
    display: flex;
    align-items: center;
    justify-content: center;
    //width: 200px;
    margin: 0 auto 15px auto;
    //height: 32px;
    color: #fff;
    font-size: 20px;
    font-weight: 800;
    border: 0;
    border-radius: 8px;
    background: #4C37CA;
    cursor: pointer;
    padding: 10px 20px;
    width: 100%;
    transition: opacity 0.3s;

    &:hover {
        opacity: 0.8;
    }

    @media (min-width: 768px) {
        height: 55px;
    }
}

.order-summary {
    margin-bottom: 18px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 7px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__item-name {
        font-weight: 500;
        font-size: 16px;
        color: #0F0F0F;
    }

    &__item-price {
        display: flex;
        align-items: center;
    }

    &__item-old-price {
        font-weight: 500;
        font-size: 16px;
        color: #A4A4A4;
      text-decoration: line-through;
    }

  &__item-current-price {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
    margin-left: 37px;
  }
}

.cart-modal__empty {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}