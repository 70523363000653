.payment {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fafafa;
  z-index: 10;
  overflow: auto;

  &__inner {
    max-width: 1440px;
    width: 100%;
    min-height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 53px;
    .logo img {
      width: 130px;
      display: block;
    }
  }
  &__close {
    padding: 5px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
  &__content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 24px;
    color: #4c37ca;
    margin-bottom: 10px;
  }
  &__domains {
    margin-bottom: 3rem;
    list-style: none;
  }
  &__price {
    font-size: 16px;
    margin-bottom: 55px;
    color: #4d4d4d;
  }
  &__full-price {
    color: #afafaf;
    text-decoration: line-through;
  }
  &__submit-wrapper {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__info {
    min-height: 6rem;
    text-align: center;

    .soc-icons {
      justify-content: center;
      zoom: 1.7;
    }

  }
  &__submit {
    display: flex;
    padding: 1rem 2rem;
    background: #4c37ca;
    font-size: 12px;
    color: #fff;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
  }
  &__loader {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (min-width: 786px) {
    &__header .logo img {
      width: 131px;
    }
    &__price {
      font-weight: 500;
      font-size: 22px;
      line-height: 29px;
      color: #4d4d4d;
      margin-bottom: 65px;
    }
  }

}

