.auth {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fafafa;
  z-index: 10;
  overflow: auto;

  &__inner {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  &__header {
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 30px;
    margin-bottom: 53px;
    .logo img {
      width: 130px;
      display: block;
    }
  }
  &__close {
    padding: 5px;
    cursor: pointer;
    img {
      width: 24px;
      height: 24px;
      display: block;
    }
  }
  &__content {
    max-width: 650px;
    width: 100%;
    margin: 0 auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__connect {
    padding: 0 1rem;
    height: 45px;
    background: #4c37ca;
    font-size: 12px;
    color: #fff;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
  }

  @media (min-width: 786px) {
    &__header .logo img {
      width: 131px;
    }
    &__connect {
      height: 50px;
      font-size: 14px;
      line-height: 1;
      padding: 0 1.5rem;
    }
  }

}



