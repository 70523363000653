.inline-nav {
    display: flex;
    overflow: auto;
    padding: 10px 20px 10px 20px;
    margin-bottom: 20px;
}

.inline-nav__item {
    padding: 0 8px;
    display: flex;
    height: 30px;
    align-items: center;
    color: #4c37ca;
    border-radius: 5px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    text-transform: uppercase;
    transition-duration: .15s;
}

.inline-nav__item._active,
.inline-nav__item:hover {
    color: #ffffff;
    background: #4c37ca;
    cursor: pointer;
}

.posts-content .posts-content__header .title {
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #becbd7;
}

.posts {
    display: flex;
    flex-direction: column;
    margin: 0 -10px 40px -10px;
    padding: 0 0 40px 0;
    border-bottom: 1px solid #dce4ed;
}

.posts .item-wrap {
    padding: 10px;
    width: 100%;
}

.posts .item-wrap .item {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    cursor: pointer;
    text-decoration: none;
}

.posts .item-wrap .item .item__preview {
    width: 100%;
    height: 177px;
    background-color: #cccccc;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.posts .item-wrap .item .item__info {
    padding: 19px 17px 22px 17px;
}

.posts .item-wrap .item .item__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #4d4d4d;
    margin-bottom: 10px;
}

.posts .item-wrap .item .item__intro {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #818181;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    margin-bottom: 31px;
}

.posts .item-wrap .item .item__date {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #818181;
}

.blog-wrap {
    background: #fafafa;
}

.language-nav {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.language-nav__item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33px;
    height: 30px;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #8aa1b6;
    border-radius: 5px;
    cursor: pointer;
    transition-duration: .15s;
}

.language-nav__item:last-child {
    margin-right: 0;
}

.language-nav__item._active,
.language-nav__item:hover {
    background: #94aec7;
    color: #ffffff;
}

.posts-content__header {
    margin-bottom: 20px;
}

@media (min-width: 526px) {
    .posts {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .posts .item-wrap {
        width: 50%;
    }
}

@media (min-width: 768px) {
    .inline-nav {
        flex-wrap: wrap;
        padding: 0;
        overflow: inherit;
        margin-bottom: 30px;
    }

    .posts .item-wrap {
        width: 33.3%;
    }

    .inline-nav__item {
        margin-bottom: 15px;
    }

    .content .title {
        font-size: 26px;
        line-height: 34px;
    }

    .posts .item-wrap .item .item__intro {
        -webkit-line-clamp: 3;
    }

    .posts-content__header {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    .language-nav {
        margin-bottom: 0;
        order: 1;
    }
}