.uploaddomain {
	&__container {
		max-width: 456px;
	}
	&__domain-name {
		word-break: break-word;
	}
	&__row {
		margin-bottom: 16px;
	}
	&__submit {
		margin-top: 48px;
	}
	&__label {
		display: block;
		margin-bottom: 8px;
	}
}
