.default-image-input-input__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.default-image-input-input__container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    flex-grow: 1;
}

.default-image-input-input__container > * {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
}

.default-image-input-input__preview {
    width: 132px;
    height: 132px;
    margin-right: 30px;
    border-radius: 100%;
    background-color: #bcc5d6;
    background-image: url(/public/assets/img/user-icon.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 38%;
}

._squared .default-image-input-input__preview {
    border-radius: 13px;
    background-image: url(/public/assets/img/img-icon.svg);
}

@media (max-width: 768px) {
    .default-image-input-input__preview {
        width: 60px;
        height: 60px;
        margin-right: 20px;
    }
}

.default-image-input-input__label {
    margin-right: 15px;
    text-transform: uppercase;
}