.header {
    border: 1px solid #dcdcdc;
    box-shadow: 0 0 12px 7px rgba(0, 0, 0, 0.11);
    position: relative;
}

.header__account {
    margin-bottom: 40px;
}

.header .inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 72px;
}

.header__left-side {
    display: flex;
    align-items: center;
    width: 100%;
}

.logo {
    position: relative;
    z-index: 3;
    display: block;
    margin-top: -10px;
    margin-right: 30px;
}

.logo img {
    width: 108px;
    display: block;
}

.header__content {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.header__content > * {
    margin-left: 20px;
}

.header__content .link {
    text-decoration: none;
    color: #4c37ca;
}

.presentation {
    display: none;
    justify-content: center;
    align-items: center;
    width: 125px;
    height: 32px;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    color: #ffffff;
    background: #4c37ca;
    border-radius: 9px;
    text-decoration: none;
    cursor: pointer;
}

@media (min-width: 786px) {
    .presentation {
        display: inline-flex;
        width: 143px;
        height: 34px;
        font-size: 14px;
        line-height: 18px;
    }
}

.presentation:hover {
    opacity: 0.8;
    transition-duration: 0.3s;
}

.cart-btn {
    position: relative;
    display: flex;
    align-items: center;
    text-decoration: none;
    width: 30px;
    height: 26px;
    margin-left: 22px;
    background: url('/public/assets/img/basket.svg') center center no-repeat;
}

@media (min-width: 768px) {
    .cart-btn {
        margin-left: 41px;
    }
}


.cart-btn-counter {
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    line-height: 1;
    min-width: 20px;
    min-height: 20px;
    background: #4c37ca;
    border-radius: 50%;
    color: #ffffff;
}

.navigation-button {
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    margin-left: auto;
}

@media (min-width: 786px) {
    .navigation-button {
        display: none;
    }
}

.navigation-button:before {
    content: '';
    display: block;
    width: 23.25px;
    height: 3.75px;
    background: #0f0f0f;
}

.navigation-button:after {
    content: '';
    display: block;
    width: 23.25px;
    height: 3.75px;
    background: #0f0f0f;
}

.navigation {
    position: fixed;
    top: 0;
    right: -100%;
    background: #ffffff;
    width: 100%;
    height: 100%;
    z-index: 2;
    padding-top: 110px;
    transition: right 0.3s;
}

@media (min-width: 786px) {
    .navigation {
        position: static;
        background: transparent;
        width: auto;
        height: auto;
        z-index: initial;
        padding-top: 0;
    }
}

.cancel-button {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: 30px;
    background: url('/public/assets/img/cancel.svg') center center no-repeat;
}

@media (min-width: 786px) {
    .cancel-button {
        display: none;
    }
}

.navigation._open {
    right: 0;
}


.navigation__items {
    display: flex;
    flex-direction: column;
    list-style: none;
    align-items: start;
    border-top: 1px solid #dce4ed;
}

@media (min-width: 786px) {
    .navigation__items {
        align-items: center;
        flex-direction: row;
        border-top: none;
    }
}

.navigation__item {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 48px;
    border-bottom: 1px solid #dce4ed;
}

@media (min-width: 786px) {
    .navigation__item {
        border-bottom: none;
    }
}

.navigation__item._parent {
    flex-direction: column;
    position: relative;
}

@media (min-width: 786px) {
    .navigation__item._mob {
        display: none;
    }
}

.navigation__item._parent:before {
    content: '';
    position: absolute;
    right: 20px;
    top: 20px;
    width: 12px;
    height: 7px;
    background: url('/public/assets/img/menu-angle.svg') center center no-repeat;
}

.navigation__item._parent._open:before {
    transform: rotate(180deg);
}

.navigation__item._parent .navigation__item-link {
    width: 100%;
}

.navigation__item-link {
    display: block;
    font-weight: 600;
    font-size: 18px;
    padding: 10px 20px;
    color: #0f0f0f;
    text-decoration: none;
    transition: color 0.3s;
}

@media (min-width: 786px) {
    .navigation__item-link {
        font-size: 17px;
        line-height: 19px;
        text-align: center;
        text-transform: initial;
        padding: 10px;
        white-space: nowrap;
    }
}

.navigation__item-link:hover {
    color: #4c37ca;

}

.navigation__sublist {
    width: 100%;
    background: #ececec;
}

.navigation__sublist .navigation__item-link {
    padding-left: 50px;
}