.error-page__container {
    flex-grow: 1;

    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding-bottom: 40px;
}

.error-page__title {
    font-size: 150px;
    line-height: 75%;
    margin-bottom: 20px !important;
}

.error-page__text {
    font-size: 20px;
    margin-bottom: 40px;
}

.error-page__img {
    width: 100px;
    height: 100px;

    background-image: url("/public/assets/img/error-cat.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    filter: drop-shadow(0px 0px 8px #4c37ca77);
}