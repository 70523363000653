
.mydomains {
  &__table {
    color: black;
    font-weight: 700;

    font-size: 16px;
    @media (min-width: 768px) {
      font-size: 18px;
    }

    .row {
      display: flex;
      flex-direction: column;
      background-color: white;
      border-radius: 13px;
      border: 1px solid #DBE1E8;
      margin-bottom: 7px;

      > * {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 57px;
      }

      &._header {
        display: grid;
        grid-template-columns: 55px 1fr 90px 40px;
        background-color: transparent;
        border: none;
      }

      &._total {
        display: grid;
        grid-template-columns: 55px 1fr 90px 40px;
        background-color: transparent;
        border: none;
        border-top: 1px solid rgba(188, 201, 214, 0.5);
        border-radius: 0;
      }

      .num {
        justify-content: center;
        align-items: center;
      }

      .name {
        justify-content: flex-start;
        overflow: hidden;
        padding-right: 10px;

        .name-wrapper {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .price {
        display: flex;
        flex-direction: row;

        .title {
          flex-grow: 1;
        }

        .primary {
          margin-left: 19px;
        }

        .secondary {
          color: #A4A4A4;
          text-decoration: line-through;
          font-weight: 500;
        }
      }

      .action {
        .btn-toggle {
          display: block;
          width: 14px;
          height: 14px;
          background-image: url("../../../../public/assets/img/account-domains-arrow-up.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          transition-duration: .3s;
        }
      }

      &._opened .action .btn-toggle {
        transform: rotate(180deg);
      }

      .container {
        display: grid;
        grid-template-columns: 55px 1fr 90px 40px;
        @media (min-width: 768px) {
          font-weight: 500;
        }

        > * {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .name {
          justify-content: flex-start;
        }

        .price {
          justify-content: flex-end;
        }

      }

      &._opened .container {
        @media (max-width: 768px) {
          grid-template-columns: 55px 2fr 40px;
        }

        padding: 10px 0;

        margin-left: -1px;
        margin-right: -1px;
        border: 1px solid #DBE1E8;
        border-top-color: transparent;
        border-radius: 13px;
        @media (min-width: 768px) {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }

        .price {
          display: none;
          @media (min-width: 768px) {
            display: flex;
          }
        }


        .name .name-wrapper {
          word-break: break-all;
        }
      }

      .additional {
        display: none;
        padding: 13px 25px 25px 25px;
        font-weight: 400;
        color: #6C6C73;

        .price {
          padding: 0 16px 13px 0;
          border-bottom: 1px solid rgba(188, 201, 214, 0.5);
          margin-bottom: 13px;

          @media (min-width: 768px) {
            display: none;
          }
        }
      }

      &._opened .additional {
        display: block;
      }
    }


  }

}

.mydomains__loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}

.mydomains__empty_list {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex-grow: 1;
}
