.default-btn {
    display: inline-block;
    padding: 8px 40px;
    color: white;
    text-align: center;
    background-color: #4c37ca;
    border-radius: 100px;
    text-transform: uppercase;
    transition-duration: .15s;
    cursor: pointer;
    user-select: none;
    font-weight: 800;
}

.default-btn > * {
    color: white;
    text-decoration: none;
}

.default-btn:hover {
    opacity: 0.8;
}

.default-btn:active {
    opacity: 0.65;
}

.default-btn._type-squared {
    border-radius: 13px;
}

.default-btn._type-round {
    border-radius: 100%;
    padding: 20px;
}

.default-btn._color-gray {
    background-color: #bcc9d6;
}

.default-btn._color-yellow {
    color: #4d4d4d;
    background-color: #f6c30e;
}

.default-btn._disabled {
    background-color: #d2d2d2;
}
