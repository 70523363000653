.registration {
  width: 100%;
  max-width: 414px;
  margin: 0 auto;
  padding: 50px 24px;

  &__title {
    margin-bottom: 52px;
    font-weight: 800;
    font-size: 35px;
    line-height: 48px;
    text-align: center;
    color: #0F0F0F;
  }

  &__continue-list {
    margin-bottom: 18px;
    border-bottom: 1px solid #D7D7D7;
  }

  &__continue-item {
    width: 100%;
    display: flex;
    align-items: center;
    height: 60px;
    background: #FFFFFF;
    border: 1.2px solid #C7C7C7;
    border-radius: 8px;
    margin-bottom: 9px;
    padding: 0 24px;
    cursor: pointer;

    img {
      width: 42px;
      height: 42px;
      display: block;
      margin-right: 23px;
      object-fit: contain;
      object-position: center;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      border-color: #4c37ca;
    }
  }

  &__policy {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    color: #62626A;
    margin-bottom: 35px;
    padding: 0 4px 18px 0;
    border-bottom: 1px solid #D7D7D7;

    a {
      text-decoration: none;
      cursor: pointer;
      color: #4c37ca;
      transition: opacity 0.3s;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__footer-text {
    margin-bottom: 13px;
    text-align: center;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 59px;
    background: #4C37CA;
    border: 1.2px solid #4C37CA;
    border-radius: 8px;
    font-weight: 700;
    font-size: 21px;
    color: #FFFFFF;
    transition: opacity 0.3s;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }

    &._white {
      background: #fff;
      color: #0F0F0F;
      border: 1.2px solid #C7C7C7;
    }
  }
}

.login-form {
  margin-bottom: 60px;

  &__fields {
    margin-bottom: 61px;
  }

  &__field-wrap {
    position: relative;

    &:first-child {
      margin-bottom: 9px;
    }
  }

  &__field-error {
    position: absolute;
    left: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FF0000;

    &._top {
      top: -25px;
    }

    &._bottom {
      bottom: -25px;
    }
  }

  &__field {
    width: 100%;
    height: 59px;
    padding: 15px 26px;
    background: #FFFFFF;
    border: 1.2px solid #C7C7C7;
    border-radius: 8px;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    cursor: pointer;

    &::placeholder {
      color: #A4A4A4;
    }

    &:hover,
    &:focus {
      border-color: #4C37CA;
    }

    &._pass {
      padding-right: 65px;
    }
  }

  &__field-icon-eye {
    position: absolute;
    right: 0;
    top: 0;
    width: 59px;
    height: 59px;
    cursor: pointer;
    background: url('../../../../public/assets/img/eye-hidden.svg') center center no-repeat;

    &._show {
      background: url('../../../../public/assets/img/eye-visible.svg') center center no-repeat;
    }
  }
}


.choice-wallet {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 37px;
    width: 223px;
    height: 223px;
    background: #FFFFFF;
    box-shadow: 1.18673px 2.37346px 7.12039px 1.18673px rgba(0, 0, 0, 0.12);
    border-radius: 17.801px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-preview {
    display: flex;
    width: 192px;
    height: 170px;
    margin-bottom: 5px;

    img {
      display: block;
      width: 100%;
    }
  }

  &__item-name {
    font-weight: 700;
    font-size: 23.7346px;
    line-height: 32px;
    color: #0F0F0F;
  }
}