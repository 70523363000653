.domain-page-editor_tab-buttons_container {
    border-bottom: 4px solid #4c37ca;
    margin-bottom: 20px;
}

.domain-page-editor_tab-buttons {
    display: flex;
    align-items: flex-end;
    justify-content: start;
    margin-bottom: -2px;
}

.domain-page-editor_tab-buttons > * {
    margin: 0 10px;
}

.domain-page-editor_tab-buttons .domain-page-editor_tab-button {
    padding-left: 20px;
    padding-right: 20px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: #4c37ca;
    background-color: transparent;
}

.domain-page-editor_tab-buttons .domain-page-editor_tab-button._active {
    color: white;
    background-color: #4c37ca;
}

.domain-page-editor_inner {
    display: flex;
    flex-grow: 1;
    padding-bottom: 20px;
}


.domain-page-editor_side {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 0;
}

.domain-page-editor_side._left {
    padding-right: 10px;
    padding-bottom: 40px;
}

.domain-page-editor_title {
    text-transform: uppercase;
}

.domain-page-editor_type-list {
    list-style: none;
    padding-left: 10px;
    text-transform: capitalize;
}

.domain-page-editor_type-list_item {
    display: flex;
}


@media (max-width: 768px) {
    .domain-page-editor_side._right {
        display: none;
    }
}

.domain-page-editor_side._right iframe {
    width: 100%;
    height: 100%;
    min-height: 500px;
    border: 2px solid #4c37ca;
}

@media (max-width: 768px) {
    .domain-page-editor_side._right iframe {
        border: 1px solid #4c37ca;
    }
}

.domain-page-editor_side._right._active iframe {
    min-height: 1500px;
}

.color-picker_wrapper {
    margin-top: 20px;
}

.color-picker_wrapper_button {
    margin-bottom: 20px;
}

.color-picker-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
}

.color-picker-row_title_key {
    font-weight: 700;
    margin-right: 15px;
    text-transform: capitalize;
}

.color-picker-row_title_value {
}

.color-picker-row_value_hex, .color-picker-row_value_alpha {
    width: 100%;
    height: 25px;
    border-radius: 6px;
}

.color-picker-row_value_alpha_wrapper {
    padding: 8px;
    background: white;
    border-radius: 5px;
    box-shadow: rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px;
}

.color-picker-row_popover {
    position: absolute;
    z-index: 2;
}

.color-picker-row_popover_close {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.domain-page-editor_posts_item {
    margin-bottom: 20px;
    border-bottom: 1px solid gray;
}


.domain-page-editor_background_list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 300px;
    overflow-y: auto;
}

.domain-page-editor_background_item {
    width: 150px;
    max-width: 29%;
    height: 75px;
    margin: 2px;

    border-radius: 13px;
    border: 4px solid transparent;
    transition-duration: .3s;
    overflow: hidden;
}

.domain-page-editor_background_item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.domain-page-editor_background_item._active {
    border: 4px solid #4c37ca;
}

.color-picker-preview_container {
    display: flex;
    flex-wrap: wrap;
}

.color-picker-preview_item {
    width: 1.7em;
    height: 1.7em;
    border-radius: 100%;
    display: flex;
    overflow: hidden;

    margin-right: 0.7em;
    box-shadow: rgb(0 0 0 / 40%) 0px 0px 5px 0px;

    cursor: pointer;
    transition-duration: .3s;
}

.color-picker-preview_item:hover {
    box-shadow: rgb(0 0 0 / 60%) 0px 0px 8px 0px;
}

.color-picker-preview_item_color {
    flex-grow: 1;
}
