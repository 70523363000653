.default-textarea__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 15px 0;
}

.default-textarea__container {
    width: 100%;
}

.default-textarea {
    width: 100%;
    min-height: 115px;
    padding: 16px;
    border: 1px solid #4c37ca;
    border-radius: 13px;
    resize: vertical;
    font-family: 'Manrope', sans-serif;
}

.default-textarea::placeholder {
    font-family: 'Manrope', monospace;
    color: #afafaf;
}

.default-textarea__label {
    margin-right: 15px;
    text-transform: capitalize;
    font-weight: 700;
}

.default-textarea__maxlength-counter__container {
    font-size: 10px;
    text-align: right;
}