.button {
    display: inline-block;
    padding: 8px 40px;
    color: white;
    text-align: center;
    background-color: #4c37ca;
    border: none;
    border-radius: 100px;
    text-transform: uppercase;
    transition-duration: .15s;
    cursor: pointer;
    user-select: none;
    font-weight: 800;

    & > * {
        color: white;
        text-decoration: none;
    }

    &:hover {
        opacity: 0.8;
    }

    &:active {
        opacity: 0.65;
    }

    &_type {
        &_squared {
            border-radius: 13px;
        }
        &_round {
            border-radius: 100%;
            padding: 20px;
        }
    }

    &_color {
        &_gray {
            background-color: #bcc9d6;
        }
        &_yellow {
            color: #4d4d4d;
            background-color: #f6c30e;
        }
    }

    &_disabled {
        background-color: #d2d2d2;
    }

}
