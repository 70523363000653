.soc-icons {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    justify-content: center;
}

@media (min-width: 786px) {
    .soc-icons {
        margin-bottom: 0;
    }
}

.soc-icons .icon {
    text-decoration: none;
    display: inline-flex;
    margin-left: 21px;
    width: 45px;
    height: 45px;
    background-image: url(/public/assets/img/icons.svg);
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: 167px;
}

.soc-icons .icon:hover {
    opacity: 0.8;
}

.soc-icons .icon:first-child {
    margin-left: 0;
}

.soc-icons .icon._dc {
    background-image: url(/public/assets/img/dsc-desk-retina.svg);
    background-size: contain;
}

.soc-icons .icon._md {
    background-image: url(/public/assets/img/md-desk-retina.svg);
    background-size: contain;
}

.soc-icons .icon._tg {
    background-image: url(/public/assets/img/tg-desk-retina.svg);
    background-size: contain;
}

.soc-icons .icon._tw {
    background-image: url(/public/assets/img/tw-desk-retina.svg);
    background-size: contain;
}

.soc-icons .icon._yt {
    background-image: url(/public/assets/img/yt-desk-retina.svg);
    background-size: contain;
}

.soc-icons .icon._yt {
    background-image: url(/public/assets/img/yt-desk-retina.svg);
    background-size: contain;
}


.soc-icons .icon._li {
    background-image: url(/public/assets/img/li-desk-retina.svg);
    background-size: contain;
}

.footer__container {
    background-color: #4c37ca;
}

.footer {
    display: flex;
    flex-direction: column;
    padding: 20px 0 40px;
    width: 100%;
}

@media (min-width: 768px) {
    .footer {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 0;
    }
}

.footer__nav__container {
    padding-top: 40px;
    padding-bottom: 15px;
    text-transform: uppercase;
}

@media (min-width: 786px) {
    .footer__nav__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 58px;
        padding-bottom: 0;
    }
}

.footer__nav__content {
    display: flex;
    flex-direction: row;
}

@media (max-width: 768px) {
    .footer__nav__content {
        padding: 40px 0 0;
        flex-wrap: wrap;
    }
}

.footer__nav__content > * {
    margin-left: 10px;
    margin-right: 10px;
}

.footer__nav__content > *:last-child {
    margin-right: 0px;

}

.footer__nav__content .link.active {
    text-decoration: underline;
}

.footer__nav__content .link {
    text-decoration: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
}

@media (max-width: 768px) {
    .footer__nav__content > * {
        margin-bottom: 20px;
    }
}


.copyright {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #ffffff;
    padding: 10px 0;
    order: 10;
}

@media (min-width: 786px) {
    .copyright {
        font-weight: 500;
        font-size: 18px;
        line-height: 16px;
        text-align: center;
        color: #a8b7c5;
        order: 0;
    }
}
