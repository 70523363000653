.back-button {
    margin-bottom: 40px;
    display: inline-block;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none;
    color: #4c37ca;
}
.back-button::before {
    margin-right: 10px;
    margin-bottom: -1px;
    display: inline-block;
    width: 6px;
    height: 12px;
    line-height: 18px;
    vertical-align: center;
    content: '';
    background: url('../../../public/assets/img/back-button-arrow.svg') center center no-repeat;
}
.blog-item-content {
    margin: 0 auto;
    padding-bottom: 80px;
    max-width: 714px;
}

.blog-item-content__header {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
}

.blog-item-content .preview {
    margin-bottom: 20px;
    padding-bottom: 63%;
    width: 100%;
    background-color: #cccccc;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.blog-item-content .title {
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    color: #4d4d4d;
}

.blog-item-content .subtitle {
    margin-bottom: 20px;
    font-size: 20px;
    line-height: 26px;
    font-weight: 400;
    color: #b3b3b3;
}

.blog-item-content .blog-item-content__header .section,
.blog-item-content .blog-item-content__header .date {
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #afafaf;
}

.blog-item-content .info .text {
    padding-bottom: 40px;
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
    color: #4d4d4d;
}
.blog-item-content .info .text > *,
.blog-item-content .info .text img {
    max-width: 100%;
}

.blog-item-content .info .text p {
    margin-bottom: 20px;
}

.blog-item-content .share {
    margin-bottom: 20px;
    padding-bottom: 20px;
    width: 100%;
    border-bottom: 1px solid #dce4ed;
}

.blog-item-content .share .share__title {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 1;
    font-weight: 400;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #4c37ca;
}
.blog-item-content .share .share__items {
    display: flex;
}
.blog-item-content .share .share__item {
    margin-right: 20px;
    width: 25px;
    height: 25px;
    flex-shrink: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    cursor: pointer;
    transition-duration: .15s;
}

.blog-item-content .share .share__item:hover {
    opacity: 0.8;
}

.blog-item-content .share .share__item:last-child {
    margin-right: 0;
}

.blog-item-content .share .share__item._tg {
    background-image: url('../../../public/assets/img/tg-mint.svg');
}

.blog-item-content .share .share__item._tw {
    background-image: url('../../../public/assets/img/tv-mint.svg');
}
.blog-item-content .share .share__item._in {
    background-image: url('../../../public/assets/img/in-mint.svg');
}

.blog-item-content .share .share__item._fb {
    background-image: url('../../../public/assets/img/fb-mint.svg');
}

.subscribe-form {
    border-bottom: 1px solid #dce4ed;
    margin-bottom: 40px;
}

.subscribe-form__middle-block {
    width: 100%;
    max-width: 714px;
    margin: 0 auto;
    padding-bottom: 40px;

}

.subscribe-form__title {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: .01em;
    text-transform: uppercase;
    color: #4c37ca;
}

.subscribe-form__field {
    margin-bottom: 10px;
    padding: 24px;
    display: block;
    width: 100%;
    height: 60px;
    font-size: 15px;
    background: #ffffff;
    border: 2px solid #4c37ca;
    border-radius: 10px;
}

.subscribe-form__field::placeholder {
    letter-spacing: .01em;
    color: #b3b3b3;
}

.subscribe-form__field-description {
    margin-bottom: 20px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: .01em;
    color: #4d4d4d;
}

.subscribe-form__submit-button {
    display: flex;
    width: 248px;
    height: 60px;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    line-height: 22px;
    font-weight: 500;
    letter-spacing: .01em;
    color: #ffffff;
    background: #4c37ca;
    border-radius: 10px;
    border: 0;
    cursor: pointer;
}

.subscribe-form__submit-button:hover {
    opacity: 0.8;
}

.recommended-posts__title {
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 25px;
    line-height: 33px;
    align-items: center;
    color: #4c37ca;
}

@media (min-width: 768px) {
    .blog-item-content .info {
        margin-right: 18px;
        padding-right: 0;
        flex-grow: 1;
    }

    .blog-item-content .info .title {
        margin-bottom: 40px;
        font-size: 40px;
        line-height: 53px;
        font-weight: 500;
        color: #4d4d4d;
    }

    .blog-item-content .info .text {
        margin-bottom: 0;
        font-size: 15px;
    }

    .blog-item-content .preview {
        margin-bottom: 40px;
    }

    .recommended-posts .posts .item-wrap {
        width: 50%;
    }

    .subscribe-form__inner {
        display: flex;
    }

    .subscribe-form__field-wrapper {
        width: 100%;
        margin-right: 10px;
    }

    .blog-item-content .blog-item-content__header .section, .blog-item-content .blog-item-content__header .date {
        font-size: 15px;
    }

    .blog-item-content .title {
        font-weight: 500;
        font-size: 40px;
        line-height: 53px;
    }

    .blog-item-content .subtitle {
        font-weight: 400;
        font-size: 25px;
        line-height: 33px;
    }

    .subscribe-form__field-description {
        margin-bottom: 0;
    }
}
.blog-item-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
}
